/** 
 * For global variables 
*/

/** 
 * For color
 */
$brand-color: #FFE238;
$yellow: #FFE238;
$dark: #181818;
$grey: #888888;
$white: #ffffff;
$black: #000000;
$pink: #FF9999;
// $grey: #f2f2f2;
$green: #1e3932;
$red: #eb2b2b;

$small: 14px;
$medium: 16px;
$large: 24px;
$largebold: 48px;

$thin: 100;
$light: 300;
$regular: 500;
// $medium: 600;
$bold: 700;
$superbold: 900;

$h1: 40px;
$h2: 36px;
$h3: 30px;
$h4: 24px;
$h5: 20px;
$h6: 14px;