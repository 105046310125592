.portfolio{
    background-color: $dark;
    padding: 30px 0 10px 0 ;
    color: $white;
    text-align: center;
}
.divRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .divCol{
        float: left;
        width: 16%;
        padding: 0 10px;
    }
}
.partner-register{
    text-align: left;
    padding: 10px 50px 30px 50px;
    background-color: #1e3932;
    color: $white;
    textarea{
        width: 100%;
        height: 100px;
        padding: 10px 20px;

    }
    .inputText{
        width: 100%;
        height: 30px;
        padding: 0 20px;
    }
    button{
        width: 150px;
        height: 40px;
        font-weight: bolder;
        color: $green;
        text-transform: uppercase;
        border-radius: 50px;
    }
}