header{
    // border: 1px solid rgba(0, 0, 0, 0.8);
    font-size: $small;
    display: block;
    // float: right;
    padding: 10px 0;
    // border-bottom-style: dotted;
    // border-right-style: solid;
    // border-bottom-style: solid;
    border-width: 1px;
    border-color: $grey;
    text-transform: capitalize;
    li{
        float: right;
        // padding: 0 0 0 10px;
        padding-left: 10px;
        a{
            display: block;
            text-align: center;
            padding: 0.1em 0.5em;
        }
        a:hover{
            background-color: $green;
            color: $white;
        }
    }
}
nav{
    padding: 20px 0;
    .navbar{
        li{
            margin: auto;
            float: right;
            padding: 20px 0 20px 20px;
            // padding-left: 20px;
        }
        a{
            display: block;
            text-align: center;
            padding: 0.5em;
            font-size: $small;
            text-transform: uppercase;
            line-height: 16px;
            font-weight: bold;
            font-style: normal;
        }
        a:hover{
            background-color: $brand-color;
        }
    }
}
.logo{
    max-height: 70px;
}
.copyright{
    // background-color: #343a40;
    text-align: right;
    // color: $white;
}
footer{
    padding: 80px 0 0 0;
    .grid-container{
        display: grid;
        grid-template-columns: auto auto auto auto;
    }
    a{
        font-weight: normal;
        display: inline-block;
    }
    a:hover{
        background-color: $yellow;
        color: $dark;
    }
    a:visited{
        color: $grey;
    }
    .logo{
        max-height: 50px;
    }
    .office{
        max-width: 400px;
    }
}
.services{
    text-align: center;
    // max-width: 80%;
    // margin: 50px auto;
    .grid-services{
        display: grid;
        grid-template-columns: auto auto;
    }
    img{
        max-width: 100%;
    }
}
//  unuse
.menu1{
    font-size: $small;
    text-transform: uppercase;
    line-height: 16px;
    font-weight: bold;
    font-style: normal;
}
.menu2{
    font-size: $small;
    text-transform: uppercase;
    // letter-spacing: 0.15em;
    line-height: 16px;
    font-weight: bold;
    font-style: normal;
}
// unuse
.text1{
    font-size: $medium;
    letter-spacing: 0.05em;
    line-height: 180%;
}
.text2{
    font-size: $small;
    letter-spacing: 0.05em;
    line-height: 180%;
    padding: 10px 0;
}

.client{
    background-color: $grey;
    text-align: center;
    margin-bottom: 50px;
    img{
        max-height: 15px;
        // max-width: 100px;
        padding: 40px;
    }
}
.accreditations{
    clear: both;
    text-align: center;
    img{
        max-height: 150px;
        max-width: 300px;
        padding: 10px;
    }
    .title{
        padding: 50px 0;
    }
}
.partner{
    clear: both;
    text-align: center;
    img{
        max-height: 80px;
        max-width: 200px;
        padding: 10px;
    }
    .title{
        padding: 50px 0;
    }
}
.hiring{
    clear: both;
    text-align: center;
    img{
        // max-height: 100%;
        max-width: 100%;
        padding: 0 10px;
    }
}
.tools{
    text-align: center;
    margin: 50px 0;
    img{
        max-height: 80px;
        max-width: 200px;
        padding: 10px;
    }
}
.undeline{
    text-decoration: underline;
}
.heading{
    padding: 80px 0 0 0;
    background-image: url(/assets/images/iris-hc2.jpg);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 50%;
    background-color: $white;
    overflow: auto;
    .motto{
        max-width: 66%;
        a:hover{
            background-color: $yellow;
            color: $dark;
            border: 2px solid $yellow;
        }
        .sub-motto{ max-width: 70%; }
    }
}
.btn-call{
    padding: 0.5em 1.5em;
    display: inline-block;
    // background-color: $grey;
    border: 2px solid rgba(0, 0, 0, 0.8);
    margin-right: 30px;
}
.btn-touch{
    padding: 0.5em 1.5em;
    display: inline-block;
    background-color: $yellow;
    border: 2px solid rgba(224, 214, 214, 0.8);
    margin-right: 30px;
}
.btn{
    text-transform: uppercase;
    font-size: $small;
    font-weight: normal;
}
a:hover{
    background-color: $yellow;
}

.s30 {
float: left;
width: 30%;
// padding: 50px;
}
.s20{
    float: left;
    width: 20%;
    // padding: 50px;
    }
.s40 {
float: left;
width: 40%;
}
.s60 {
float: left;
width: 60%;
}
.s70 {
    float: left;
    width: 70%;
}
.s80 {
    float: left;
    width: 80%;
}
  
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.news{
    img{
        max-width: 400px; 
        padding: 50px 0;
    }
    .content{
        padding: 100px 50px;
    }
}
.solutions{
    img{
        max-width: 300px; 
        padding: 30px 0;
    }
    .content{
        padding: 10px 50px;
    }
}
.right{
    float: right;
}
.left{
    float: left;
}

.pentest{
    width: 100%;
    text-align: left;
    padding: 30px;
    .icon{
        max-width: 20%;
        float: left;
        font-size: 4em;
        padding: 50px 30px;
        // background-color: green;
        margin: 30px;
    }
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
  }
  .column {
    float: left;
    width: 50%;
    // border: 1px solid rgba(0, 0, 0, 0.8);
    
    // margin-top: 6px;
    // padding: 20px;
  }
  .notification{
      span{
        color: red;
        position: absolute;
        top: 15px;
      }
      
  }
.call{
    background-color: $brand-color;
    color: $dark;
    text-align: center;
    padding: 10px;
    margin: 50px 300px;
}


//   blog
.blog{
    text-align: center;
    margin: 50px auto;
    .blog-content{
        padding: 0 200px 100px 200px;
        text-align: left;
        h3{
            padding: 0;
            margin-block-end: 0px;
            margin-block-start: 0px;
        }
        li{
            border: 1px solid black;
            margin: 20px;
            padding: 20px;
        }
    }
}

.post{
    padding: 100px 0;
    p{
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    .post-content{
        padding-right: 150px;
        ul{
            padding-left: 50px;
        }
        li{
            list-style-type: square;
            list-style: square;

        }
        a{
            text-decoration: underline;
        }
        img{
            max-width: 100%;
        }
    }
    .quote{
        padding: 0;
        text-align: right;
        h4::first-letter{
            color: white;
            background-color: rgb(39, 37, 37);
            border-radius: 2px;
            box-shadow: 3px 3px 0 $yellow;
            font-size: 150%;
            margin-right: 1px;
        }
    }
    ul{
        padding-left: 10px;
    }
    
}
.tag{
    padding: 0.1em 0.5em;
    display: inline;
    background-color: $grey;
    color: $dark;
    border-radius: 10px;
   
}

.security{
    background-color: $red;
    color: $white;
}
.devops{
    background-color: $green;
    color: $white;
}
.devsecops{
    background-color: $pink;
    color: $dark;
}
.certified{
    // padding: 30px;
    img{
        max-width: 300px;
        max-height: 80px;
    }
}
.company-team{
    // padding: 30px;
    img{
        max-width: 500px;
        max-height: auto;
        padding: 30px;
    }
}
.grid-item{
    max-width: 100%;
}

.badges{
    padding: 50px;
    background-color: #e5e5e5;
    text-align: center;
    img{
        max-width: 300px;
    }
}


// for grazy menu
.mobileShow {display: none;}

@media only screen and (max-width : 768px){ 
    .mobileShow {display: inline;}
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: $brand-color;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: $dark;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: $white;
    display: block;
    background-color: $dark;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  #main {
      float: right;
    transition: margin-left .5s;
    padding: 16px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
// end for grazy menu



.dataRow{
    .dataRow:after{
        content: "";
        display: table;
        clear: both;
    }
    .dataCol{
        float: left;
        width: 24%;
        margin: 5px;
    }
    .ddd{
        text-align: center;
        border: 1px solid #ccc!important;
        padding: 40px 20px;
    }
    .featureIcon{
        img{
            max-width: 100px;
            max-height: 80px;
        }
        background-color: $yellow;
        border-radius: 50%;
        i{
            font-size: 40px;
        }
    }
}

.dataRow1{
    padding: 200px 0;
    text-align: left;
    display: grid;
    .dataCol1{
        float: left;
        width: 50%;
        // margin: 5px;
        
    }
    .dataContent{
        background-color: #fff6cc;
        padding: 50px 70px;
    }
    .dataS{
        position: absolute;
        left: 47%;
        top: 350px;
        width: 40%;
        padding: 50px 50px 50px 100px;
        border: 3px solid $dark !important;
    }
    h4{
        position: relative;
        text-transform: uppercase;
        padding: 20px 0;
    }
    h4::after {
        display: block;
        height: 0;
        width: 25%;
        border-bottom: 6px solid $dark;
        content: "";
    }
    ul li {
        padding-bottom: 10px;
    }
}