* { 
    box-sizing: border-box; 
    margin: 0;
}
html{
    background-color: $white;
    color: $dark;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $medium;
    letter-spacing: 0.05em;
    line-height: 180%;
 
}
body{ padding: 0 20px; }
ul, li{
    list-style: none;
    list-style-type: none;
    // overflow: hidden;
    margin: 0;
    padding: 0;
}
nav, header{
    overflow: hidden;
    margin: 0;
    padding: 0;
}
a{
    list-style: none;
    text-decoration: none;
    color: $dark;
    cursor: pointer;
    font-weight: bold;
}
.container{
    max-width: 1255px;
    margin: 0 auto;
    background-color: $white;
}
.uppercase{
    text-transform: uppercase;
}
h1, h2, h3, h4, h5, h6{
    line-height: 1.5em;
    // text-transform: capitalize;
    font-weight: bold;
    font-style: normal;
    margin-top: 0;
    // line-height: 2em;
    
}
p{
    margin-block-end: 1.5em;
    margin-block-start: 1.5em;
}
h1{
    font-size: $h1;
}
h2{
    font-size: $h2;
}
h3{
    font-size: $h3;
}

h4{
    font-size: $h4;
}
h5{
    font-size: $h5;
    font-style: initial;
}
h6{
    font-size: $h6;
    font-style: underline;
}
span{
    font-size: $small;
    font-weight: lighter;
}
.clear{
    padding-top: 100px;
    clear: both;
}
.center{
    margin: 0 auto;
}

// blog
.highlight{
    padding: 10px;
    white-space: pre-wrap; 
    color: $white;
    font-size: $small;
    background-color: $dark;
}

// SEO
.h1-seo, .h2-seo, .h3-seo, .h4-seo, .h5-seo{
    color: $dark;
    font-style: normal;
    font-weight: normal;
    text-transform: none ;
    font-size: $small;
    letter-spacing: 0.05em;
    line-height: 180%;
}
mark{
    background-color: $yellow;
    color: $dark;
    font-weight: bold;
}
.icon{
    font-size: 30px;
    padding: 15px 0;
    a:hover{
        background-color: $white !important;
    }
    img{
        max-width: 30px;
    }
}

.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
    text-align: center;
}
.gridItem{
    padding: 10px;
}
hr{
    border: 0; 
    height: 1px; 
    background-image: -webkit-linear-gradient(left, #f0f0f0, $grey, #f0f0f0);
    background-image: -moz-linear-gradient(left, #f0f0f0, $grey, #f0f0f0);
    background-image: -ms-linear-gradient(left, #f0f0f0, $grey, #f0f0f0);
    background-image: -o-linear-gradient(left, #f0f0f0, $grey, #f0f0f0); 
}