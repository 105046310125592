
@media only screen and (max-width: 600px) {
    .clear{padding: 10px;}
    h1{ font-size: $h3; }
    h2{ font-size: $h4; }
    h3{ font-size: $h5; }
    h4{ font-size: $h6; }
    .s60, .s40{ float: none; width: 100%;}
    .company-team img { max-width: 100%; }
    .company{
        // position: fixed;
        bottom: 0;
    }
    .mobile-hide, .call, .news, .client, .tools, .navbar, #toc{
        display: none;
    }
    .heading{
        padding: 50px 0;
        background-size: auto;
        background-color: $white;
    }
    .logo{
        max-width: 300px;
    }
    .solutions{
        .content{
            padding: 0;
        }
    }
    .services{
        padding: 0;
        margin: 0 auto;
        max-width: 100%;
    }
    .post{
        .post-content{
            border-left: none;
            padding: 0;
        }
        .s60{
            width: 100%;
        }
    }
    .s70{
        width: 100%;
    }
    .clearfix{
        .s40{
            // display: none;
        }
        .s60{
            width: 100%;
        }
    }
    
    .dataRow .dataCol{
        text-align: center;
        display: inline;
        width: 100%;
        padding: 20px 10px;
        margin: 10px 0;        
    }
    .dataRow1 {
        padding: 20px 0;
        .dataCol1{
            display: inline;
            position: relative;
            width: 100%;
            .dataContent{
                padding: 20px;
            }
        }
        .dataS{
            position: relative;
            display: inline;
            top: auto;
            left: auto;
            padding: 20px;
            border: none !important;
        }
 
    }
    footer{
        .grid-container{
            display: inline;
            .grid-item{
                padding: 10px 0;
            }
        }
    }
    .heading{
        .motto{
            max-width: 100%;
            .sub-motto {
                max-width: 100%;
            }
        }
    } 
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .mobile-hide, .call, .news, .client, .tools, #toc{
        display: none;
    }
    .dataRow{
        text-align: center;
        margin: 0;
        .dataCol{
            width: 100%;
        }
        .ddd{
            padding: 20px;
        }
    }
    .heading{
        padding: 10px 0;
        background-image: none;
    }
    .services{
        padding: 0;
        margin: 0 auto;
        max-width: 100%;
    }
    
    
    .s70{
        width: 100%;
    }
    .post{
        .post-content{
            border-left: none;
            padding: 0;
        }
        .s60{
            width: 100%;
        }
    }
    .dataCol, .dataRow{
        flex: 100%;
    }
    .dataRow1 {
        padding: 20px 0;
        .dataCol1{
            display: inline;
            position: relative;
            width: 100%;
            .dataContent{
                padding: 20px;
            }
        }
        .dataS{
            position: relative;
            display: inline;
            top: auto;
            left: auto;
            padding: 20px;
            border: none !important;
        }
 
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    
    .clearfix{
        img{
            max-width: 100%;
        }
        
    }
    .pentest{
        .icon{
            display: none;
        }
    }
    .blog{
        .blog-content{
            padding: 0 100px;;
    
        }
    }
    .services{
        max-width: 100%; 
        .grid-services{
            // padding: 0 100px;
            .pentest{
                max-width: 80%;
            }
        }
    }
    .post{
        .post-content{
            border: none;
            padding: 0;
        }
        .s60{
            width: 100%;
        }
    }
    .dataRow1{
        .dataCol1{
            .dataContent{
                padding: 20px;
            }
            .dataS{
                padding: 30px;
            }
        }
    }

}

@media only screen and (max-width: 1024px) {
    .tools, #toc{
        display: none;
    }
    .heading{
        background-image: none;
    }
    footer{
        .grid-container{
            grid-template-columns: auto auto;
            .grid-item{
                padding: 10px 0;
            }
        }
    }
    .pentest{
        .icon{
            padding: 100px 10px;
            margin: 10px;
        }
    }
    .dataCol1{
        .dataS{
            width: 50%;
        }
    }
    
}
@media only screen and (min-width: 1024px) {
    .container{
        padding: 10px;
    }
}




  


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}